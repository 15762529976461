import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import {
  SuccessStory as SuccessStoryType,
  SuccessStoryQueryResult,
} from "app-types/success-story";
import { Title } from "components/core/Typography/Title";
import { graphql } from "gatsby";
import { PageContainer } from "styles/global.style";
import { SuccessStoryContainer } from "styles/success-stories.style";
import Loop from "components/core/Loop";
import { Col, Row } from "styled-bootstrap-grid";
import SuccessStory from "components/ui/base/SuccessStory";

interface SuccessStoryPageProps {
  data: SuccessStoryQueryResult;
  location: Location;
}

export default ({ data, location }: SuccessStoryPageProps) => {
  const successStories = data.successStories.nodes;
  const { t } = useTranslation();

  let successStoriesByCategory: any = {};
  for (let idx in successStories) {
    const successStory = successStories[idx];
    const category = successStory.category;

    if (!Object.keys(successStoriesByCategory).includes(category)) {
      successStoriesByCategory[category] = [];
    }

    successStoriesByCategory[category].push(successStory);
  }

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.success-stories.seo.title")}
        description={t("pages.success-stories.seo.description")}
        image={{
          relativePath: "meta/success-stories-from-customerly-customers.jpg",
          alt: "Success Stories from Customerly",
        }}
      />
      <PlainHeader
        title={t("pages.success-stories.title")}
        description={t("pages.success-stories.description")}
        maxWidth={600}
      />

      <PageContainer>
        <SuccessStoryContainer>
          {Object.keys(successStoriesByCategory).map((category: string) => (
            <React.Fragment key={category}>
              <Title>{category}</Title>
              <Row>
                <Loop items={successStoriesByCategory[category]}>
                  {(successStory: SuccessStoryType) => (
                    <Col key={successStory.slug} md={6} sm={12}>
                      <SuccessStory successStory={successStory} />
                    </Col>
                  )}
                </Loop>
              </Row>
            </React.Fragment>
          ))}
        </SuccessStoryContainer>
      </PageContainer>
    </GlobalWrapper>
  );
};

export const successStoriesPageQuery = graphql`
  query {
    successStories: allContentfulSuccessStory(
      sort: { fields: createdAt, order: DESC }
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        id
        slug
        name
        category
        result
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
